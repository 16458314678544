import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";

const Accordion = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      <button
        type="button"
        className="flex items-center justify-between w-full py-5 font-medium text-left text-textColor gap-3"
        onClick={handleToggle}
      >
        {isOpen ? (
          <FaMinus className="w-5 h-5 text-textColor" />
        ) : (
          <FaPlus className="w-5 h-5 text-textColor" />
        )}
        <span className="flex-grow text-left font-inter font-semibold text-textColor text-xl">
          {item.title}
        </span>
      </button>

      <div
        className={`transition-all duration-300 ease-in-out  overflow-hidden 

         ${isOpen ? "max-h-screen" : "max-h-0"}`}
      >
        <div className="py-5 text-textColor/75 text-justify font-inter font-normal text-base">
          {item.content}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
