// import React, { useState } from "react";
// import { IoMdArrowDropdown } from "react-icons/io";

// const Disclosure = ({ title, content }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleDisclosure = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="bg-white rounded-tl-none rounded-tr-xl rounded-bl-xl rounded-br-none p-2 mb-1 border text-[#2d95a5] border-[#2d95a5]">
//       <button
//         onClick={toggleDisclosure}
//         className="flex justify-between items-center w-full text-left font-semibold text-base xl:text-md transition-all duration-300 ease-in-out"
//       >
//         <span className="">{title}</span>
//         <IoMdArrowDropdown
//           className={`w-5 h-5 transform ${
//             isOpen ? "rotate-180" : ""
//           } transition-transform duration-300 ease-in-out`}
//         />
//       </button>
//       <div
//         className={`mt-4 text-black text-sm xl:text-base overflow-hidden transition-max-height duration-300 ease-in-out ${
//           isOpen ? "max-h-96" : "max-h-0"
//         }`}
//       >
//         {content}
//       </div>
//     </div>
//   );
// };

// export default Disclosure;



import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const Disclosure = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDisclosure = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div 
      className="bg-white rounded-tl-none rounded-tr-xl rounded-bl-xl rounded-br-none p-2 mb-1 border text-[#2d95a5] border-[#2d95a5]"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ transition: "max-height 1s ease" }}
    >
      <button
        className="flex justify-between items-center w-full text-left font-semibold text-base xl:text-md transition-all duration-300 ease-in-out"
        onClick={toggleDisclosure}
      >
        <span className="">{title}</span>
        <IoMdArrowDropdown
          className={`w-5 h-5 transform ${
            isOpen ? "rotate-180" : ""
          } transition-transform duration-300 ease-in-out`}
        />
      </button>
      <div 
        className={`mt-4 text-black text-sm xl:text-base overflow-hidden transition-max-height duration-300 ease-in-out ${
          isOpen ? "max-h-96" : "max-h-0"
        }`}
      >
        {content}
      </div>
    </div>
  );
};

export default Disclosure;
