import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import BlogAndArticle from "../Components/FirstComponents/BlogAndArticle";
import Ads from "../Components/FirstComponents/Ads";
import PatientView from "../Components/FirstComponents/PatientsView";
import Banner from "../Utils/Banner";

function Testimonials() {
  return (
    <>
      <div
        className="bg-gray-200 bg-no-repeat bg-contain "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className="mx-auto ">
          {/* <Hero /> */}
          {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10 uppercase">
            Testimonials
          </h1> */}
          <Banner title="Testimonials" imageUrl="/assets/Second_Banner.png" />

          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14 xl:ml-32 xl:mr-32">
            {/* <MythsFacts /> */}
            <PatientView />
            {/* <BlogAndArticle /> */}
            {/* <Ads /> */}
            {/* <Subscribe /> */}
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default Testimonials;
