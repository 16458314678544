import React from "react";
import ContactForm from "./ContactForm";

const ContactUs = () => {
  return (
    <section
      className="relative flex flex-col items-center h-full bg-cover bg-center text-white z-10"
      style={{ backgroundImage: "url(/assets/New-Hero/Contact-bg.jpeg)" }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>

      <div className="mx-auto z-50 mb-20 xl:mr-44 xl:ml-44 mt-2 lg:p-0">
        <div className="flex flex-col items-center space-y-3">
          {/* <span className="text-lg text-white font-inter font-light uppercase tracking-wider">
            Fill the form
          </span> */}
          <h1 className="text-white font-inter font-semibold text-2xl lg:text-4xl mt-5">
            Review Form
          </h1>
        </div>

        <div
          className="z-50 flex flex-col lg:flex-row w-full max-w-full bg-white shadow-lg mt-8"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1000"
          crossOrigin="anonymous"
        >
          <div className="w-3/10 flex-[3_1_0%] bg-secondary p-8 text-white flex items-center">
            <div className="flex flex-col text-center space-y-4 ">
              <img
                src="/assets/New-Hero/contact-us-img.png"
                alt="Contact Us"
                className="object-contain mx-auto"
              />
              <h1 className="text-3xl font-inter font-semibold text-center">
                Write <span className="font-extrabold">a review
                to mission organ protection team</span> 
              </h1>
            </div>
          </div>
          <div className="w-7/10 flex-[7_1_0%] bg-primary p-4 text-white">
            <div className="lg:p-10">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
