import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Ads from "../Components/FirstComponents/Ads";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import YouTubeVideoPlayer from "../Utils/YouTubeVideoPlayer";

function BlogDetails() {
  // get route params id
  const { id } = useParams();
  const [content, setContent] = useState({});

  // fetch data from api using id

  // if (!content || !content.description) {
  //   return null; // Or any other fallback behavior
  // }
  // const plainText = content.description.replace(/<[^>]*>?/gm, '');

  // Truncate the text to 300 words
  // const truncatedText = plainText.split(' ').slice(0, 5).join(' ');

  useEffect(() => {
    const callContent = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/contents/" + id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);
        setContent(data);
        console.log(content);

        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callContent();
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>

    
      <div
        className="bg-gray-200 bg-no-repeat bg-contain "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className="mx-auto mt-20">
          {/* <Hero /> */}
          <h3 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-3">
            Details of {content.title}
          </h3>
          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14 xl:ml-32 xl:mr-32">
            <section className="border-2 border-[#2d95a5] p-4 pr-4 pl-4 rounded-lg mt-4 bg-white bg-opacity-80 font-ubuntu mb-5">
              <div className="flex flex-col gap-2 md:flex-row">
                <div className="flex flex-col justify-center w-full md:w-1/2">
                  {/* <h1 className='text-[#2d95a5] font-semibold text-lg lg:text-xl xl:text-xl mb-2'>
                    {content.title}
                  </h1> */}

                  <div className="border border-[#2d95a5] rounded-tl-lg rounded-tr-lg rounded-bl-none rounded-br-none mt-4 p-4 space-y-2">
                    <p className="text-xs xl:text-sm">
                      {/* <div
                        className='w-full p-4 prose rounded-md'
                        dangerouslySetInnerHTML={{
                          __html: content.description,
                        }}
                      /> */}
                      <div>
                        {/* video */}
                        <div>
                          {content.file_path && (
                            <div className="w-full">
                              <video
                                className="w-full mt-4 rounded-md shadow-md"
                                controls={true}
                                autoPlay={false}
                                crossOrigin="anonymous"
                              >
                                <source
                                  crossOrigin="anonymous"
                                  src={content.file_path}
                                />
                              </video>
                            </div>
                          )}
                        </div>
                        <div>
                          {content.video_url && (
                            <YouTubeVideoPlayer videoLink={content.video_url} />
                          )}
                        </div>
                        <br />
                        Category:{" "}
                        {content.content_category &&
                          content.content_category.name}{" "}
                        | Language: {content.language && content.language.name}{" "}
                        | Uploaded On: {moment(content.createdAt).format("LL")}
                      </div>
                    </p>
                  </div>
                </div>

                <div className="w-full md:w-1/2">
                  {/* <img
                    src={content.thumbnail}
                    crossOrigin='anonymous'
                    alt=''
                    className='object-contain w-full h-full'
                  /> */}

                  {/* {content.description} */}
                  {/* <div className="p-5" dangerouslySetInnerHTML={{ __html: content.description }} /> */}

                  <div className="p-0">
                    <div
                      className="p-5"
                      dangerouslySetInnerHTML={{ __html: content.description }}
                    />
                    {/* {content.description.length > 5 && (
                        <Link to="#" className="block text-blue-500 hover:text-blue-700 p-5"> Read More</Link>
                      )} */}
                  </div>
                </div>
              </div>
            </section>

            {/* <Ads /> */}
            {/* <Subscribe /> */}
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default BlogDetails;
