import React from "react";
import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import ContactUs from "../Components/Common/ContactUs";
import Banner from "../Utils/Banner";

const Contact = () => {
  return (
    <>
      <div
        className="bg-contain bg-no-repeat bg-gray-200 "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        {/* <Navbar /> */}
        <NewNavbar />

        {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10 uppercase mb-5">
          Contact Us
        </h1> */}

        <Banner title="Contact Us" imageUrl="/assets/Second_Banner.png" />

        <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14 mb-10 xl:ml-32 xl:mr-32">
          <ContactUs />
        </div>
        <NewFooter />
        <Copyright />
      </div>
    </>
  );
};

export default Contact;
