import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination"; // Include pagination styles

const dummyData = [
  {
    id: 1,
    image: "/assets/New-Hero/Card-1.png",
    title: "Best Medical Network Directory For Physicians & Clients",
    description:
      "Hypertension, commonly known as high blood pressure, is a prevalent condition.",
    link: "#",
  },
  {
    id: 2,
    image: "/assets/New-Hero/Card-2.png",
    title: "The Importance of Regular Health Checkups",
    description:
      "This symptoms, causes, risk factors, diagnosis, treatment options strategies.",
    link: "#",
  },
  {
    id: 3,
    image: "/assets/New-Hero/Card-3.png",
    title: "Managing Better Stress for Better Mental Health",
    description:
      "These stories can provide valuable insights into living with a particular condition",
    link: "#",
  },
  {
    id: 4,
    image: "/assets/New-Hero/Card-2.png",
    title: "Innovative Medical Research and Development",
    description:
      "We are at the forefront of medical research, developing new treatments.",
    link: "#",
  },
];

function InfoCard() {
  return (
    <section className="bg-primary mb-1">
      <div
        className="mx-auto xl:mr-44 xl:ml-44 lg:pt-10 lg:pb-10"
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1000"
        crossOrigin="anonymous"
      >
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={1}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
        >
          {dummyData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="mx-auto rounded-xl mb-8 p-6 ">
                <div className="overflow-hidden rounded-lg">
                  <img
                    className="object-cover w-full"
                    src={item.image || "https://via.placeholder.com/150"}
                    alt={`Card ${item.id}`}
                  />
                </div>
                <div className="mt-3">
                  <div className="text-xl text-white font-inter font-semibold">
                    <Link to={item.link}>{item.title}</Link>
                  </div>

                  <p className="text-white mt-3 mb-3 text-sm font-inter font-normal">
                    {item.description}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default InfoCard;
