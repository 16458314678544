import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const Subscribe = () => {
  return (
    <>
      <section
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="300"
        className="mt-10 text-center font-ubuntu rounded-tl-none rounded-tr-xl rounded-bl-xl rounded-br-none overflow-hidden h-36 mb-10 md:h-52 bg-[#48c3d6] flex flex-col justify-center items-center"
      >
        <h1 className="text-white font-bold text-2xl md:text-3xl m-3 uppercase">
          Follow Us
        </h1>

        <div
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="300"
          className="gap-4 flex justify-center items-center p-2"
        >
          {/* Facebook Icon */}
          <a
            href="https://www.facebook.com/your-facebook-page"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="text-white text-3xl md:text-4xl cursor-pointer hover:text-[#72b7c2]" />
          </a>

          {/* Instagram Icon */}
          <a
            href="https://www.instagram.com/your-instagram-account"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-white text-3xl md:text-4xl cursor-pointer hover:text-[#72b7c2]" />
          </a>

          {/* LinkedIn Icon */}
          <a
            href="https://www.linkedin.com/in/your-linkedin-profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="text-white text-3xl md:text-4xl cursor-pointer hover:text-[#72b7c2]" />
          </a>
        </div>
      </section>
    </>
  );
};

export default Subscribe;
