import React, { useEffect, useState } from "react";
import Rating from "../../Utils/Rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

const PatientView = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const callTestimonials = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/testimonials?type=2",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        setTestimonials(data);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callTestimonials();
  }, []);

  return (
    <>
      <section
        className='mt-10 bg-[#ffffff] shadow-md p-1 rounded mb-5 '
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='300'
        crossOrigin='anonymous'
      >
        <h1 className='mb-6 text-3xl font-bold text-center mt-10'>
          Patient's Review
        </h1>

        <div className='relative '>
          <Swiper
            modules={[Pagination, Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1,
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next-custom",
              prevEl: ".swiper-button-prev-custom",
            }}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            pagination={{ clickable: true }}
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div className=''>
              {testimonials
                .filter((testimonial) => testimonial.type == 2)
                .map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <div className=' p-0 overflow-hidden  flex flex-col'>
                      <div className='mb-3'>
                        <p className='mb-2 text-2xl font-semibold text-center text-gray-600'>
                          {testimonial.name}
                        </p>

                        <Rating
                          rating={testimonial.rating}
                          className='justify-center'
                        />
                      </div>
                      <div className='flex-grow overflow-y-auto flex justify-center'>
                        <p
                          className='mb-4 text-justify w-full ml-14 mr-14 p-5 text-gray-800'
                          dangerouslySetInnerHTML={{
                            __html: testimonial.content,
                          }}
                        ></p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
          {/* Custom navigation buttons with absolute positioning */}
          <button className='hidden lg:block absolute z-10 text-[#2d95a5] transform -translate-y-1/2 rounded-full cursor-pointer hover:bg-[#91c8d0] hover:text-white left-5 swiper-button-prev-custom top-1/2'>
            <FaArrowCircleLeft className='text-3xl ' />
          </button>
          <button className='hidden lg:block absolute z-10 text-[#2d95a5] transform -translate-y-1/2 rounded-full cursor-pointer hover:bg-[#91c8d0] hover:text-white right-5 swiper-button-next-custom top-1/2'>
            <FaArrowCircleRight className='text-3xl ' />
          </button>
        </div>
      </section>
    </>
  );
};

export default PatientView;
