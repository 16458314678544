import React from "react";

const MythsFacts = () => {
  return (
    <>
      <section className="border-2 border-[#2d95a5] pr-4 pl-4 rounded-lg mt-10 bg-white bg-opacity-80 font-ubuntu ">
        <div className="">
          <h1 className="flex justify-center items-center text-[#2d95a5] font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl gap-3 p-4">
            Myths
            <div className="flex items-center justify-center relative text-white">
              <div className="w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-full bg-[#a9d6de] text-center flex items-center justify-center">
                <div className="w-10 h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 xl:w-16 xl:h-16 rounded-full bg-[#2d95a5] flex items-center justify-center">
                  Vs
                </div>
              </div>
            </div>
            Facts
          </h1>
        </div>

        <div className="flex flex-col lg:flex-row gap-8 font-ubuntu mb-5">
          <div className="w-full lg:w-1/2 space-y-10">
            <div>
              <img src="../assets/image1.png" alt="" className="w-1/2" />
              <h2 className="text-[#2d95a5] font-medium text-lg mb-2">
                Myth: All cholesterol is bad for you.
              </h2>
              <p className="">
                <span className="font-semibold">Fact: </span>
                Some types of cholesterol are essential to perform functions,
                such as making hormones and building cells. High levels of LDL
                (low-density lipoprotein), also called "bad" cholesterol raises
                the risk for heart disease and stroke. HDL (high-density
                lipoprotein), or "good" cholesterol, carries cholesterol back to
                the liver and is flushed from the body and lowers the risk for
                heart disease.¹
              </p>
            </div>

            <div>
              <img src="../assets/image2.png" alt="" className="w-1/2" />
              <h2 className="text-[#2d95a5] font-medium text-lg mb-2">
                Myth: Having high cholesterol is only a man's problem.
              </h2>
              <p>
                <span className="font-semibold">Fact: </span>
                Although atherosclerosis typically occurs later in women than in
                men, CVD remains the leading cause of death in women. Everyone's
                risk for high cholesterol goes up with age.
              </p>
            </div>

            <div>
              <img src="../assets/image3.png" alt="" className="w-1/2" />
              <h2 className="text-[#2d95a5] font-medium text-lg mb-2">
                Myth: Check cholesterol only when you've reached middle age.
              </h2>
              <p>
                <span className="font-semibold">Fact: </span>
                The American Heart Association (AHA) recommends checking
                cholesterol levels once between the ages of 9 and 11 years, and
                again between the ages of 17 and 21 years for children and young
                adults without other risk factors or a family history of early
                heart disease. After age 20, your doctor will recheck your
                cholesterol and other risk factors every four to six years as
                long as your risk remains low. 2
              </p>
            </div>
          </div>

          <div className="w-full lg:w-1/2 space-y-10">
            <div>
              <img src="../assets/image4.png" alt="" className="w-1/4" />
              <h2 className="text-[#2d95a5] font-medium text-lg mb-2">
                Myth: You can feel it if you have high cholesterol.
              </h2>
              <p>
                <span className="font-semibold">Fact: </span>
                High cholesterol usually has no signs or symptoms. Unhealthy
                cholesterol levels can't be known until it is too late-when you
                have a heart attack or stroke. Occasionally, some people may
                develop yellowish growths on their skin called xanthomas, which
              </p>
            </div>

            <div>
              <img src="../assets/image5.png" alt="" className="w-1/4" />
              <h2 className="text-[#2d95a5] font-medium text-lg mb-2">
                Myth: Only overweight and obese people have high cholesterol.
              </h2>
              <p>
                <span className="font-semibold">Fact: </span>
                People of any body type can have high cholesterol. Being
                overweight or obese increases your chances of having high
                cholesterol. 2
              </p>
            </div>

            <div>
              <img src="../assets/image6.png" alt="" className="w-1/4" />
              <h2 className="text-[#2d95a5] font-medium text-lg mb-2">
                Myth: You don't have to make diet and lifestyle changes if
                taking cholesterol medicines.
              </h2>
              <p>
                <span className="font-semibold">Fact: </span>
                Along with taking medicines prescribed by your doctor, eat a
                heart-healthy diet and get at least 150 minutes per week of
                moderate-intensity aerobic activity per week.2
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MythsFacts;
