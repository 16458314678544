import React, { useEffect, useState } from "react";

// import Modal from "../Common/Modal";
import {
  FaArrowLeftLong,
  FaArrowRightLong,
  FaCircleArrowRight,
  FaCirclePlay,
} from "react-icons/fa6";

//  import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Hero = () => {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    const callSliders = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/sliders",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);
        setSliders(data);
        console.log(sliders);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callSliders();
  }, []);

  return (
    <>
      <div
        className='flex flex-col w-full lg:-mb-10 sm: mb-4 bg-secondary'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='300'
        crossOrigin='anonymous'
      >
        <div className='relative'>
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next-custom",
              prevEl: ".swiper-button-prev-custom",
            }}
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 1,
                spaceBetween: 50,
              },
            }}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            style={{ width: "100%", height: "700px" }}
          >
            {sliders.length > 0 &&
              sliders.map((slider, index) => (
                <SwiperSlide key={index}>
                  <div className='absolute p-3 md:p-5 lg:-top-10 lg:p-24 left-0 gap-4 lg:gap-8 right-0 flex  justify-center md:items-center text-center text-white z-10'>
                    <div className='flex flex-col items-start text-left w-[600px] col-span-1'>
                      <span className='xl:text-lg md:text-sm text-xs font-inter font-light uppercase tracking-wider'>
                        {slider.title}
                      </span>
                      <h1 className=' font-inter text-base md:text-2xl lg:text-4xl xl:text-6xl font-semibold flex justify-start md:mt-3'>
                        {slider.caption}
                      </h1>
                      <p className='xl:text-base md:text-sm hidden md:block font-inter font-normal lg:mt-5 xl:mt-10 text-start'>
                        {slider.description}
                      </p>

                      <div className='md:flex mt-2 md:mt-0 gap-4 lg:gap-6'>
                        {slider.button_one_text && slider.button_one_link && (
                          <button
                            onClick={() => {
                              window.open(slider.button_one_link, "_self");
                            }}
                            className='bg-white flex items-center gap-2 text-secondary  font-inter font-semibold text-xs lg:text-base lg:mt-10 md:mt-5 p-2 px-5 rounded-full hover:bg-primary hover:text-white'
                          >
                            {slider.button_one_text}
                            <FaCircleArrowRight />
                          </button>
                        )}
                        {slider.button_two_text && slider.button_two_link && (
                          <button
                            onClick={() => {
                              window.open(slider.button_two_link, "_self");
                            }}
                            className='bg-secondary flex items-center gap-2 text-white hover:bg-white hover:text-secondary  font-inter font-semibold text-xs lg:text-base lg:mt-10 md:mt-5 p-3 px-5 rounded-full'
                          >
                            {slider.button_two_text}
                            <FaCirclePlay />
                          </button>
                        )}
                      </div>
                    </div>

                    <div className='col-span-1 flex flex-col justify-center items-center'>
                      <img
                        src={slider.image}
                        alt='Hero Image'
                        className='object-contain w-5/6 xl:w-full lg:w-[400px]'
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          {/* Custom navigation buttons with absolute positioning */}

          <button className='hidden lg:block absolute z-10  transform -translate-y-1/2 rounded-full cursor-pointer border-2 border-navbar hover:bg-transparent hover:border-navbar/95 p-3 bg-navbar/95 hover:text-white left-10 swiper-button-prev-custom top-1/2'>
            <FaArrowLeftLong size={24} className='text-3xl text-white' />
          </button>
          <button className='hidden lg:block absolute z-10 transform -translate-y-1/2 rounded-full cursor-pointer border-2 border-navbar hover:bg-transparent hover:border-navbar/95 p-3 bg-navbar/95 hover:text-white right-10 swiper-button-next-custom top-1/2'>
            <FaArrowRightLong size={24} className='text-3xl text-white' />
          </button>
        </div>
      </div>
    </>
  );
};

export default Hero;
