import NewNavbar from "../Components/Common/NewNavbar";

import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Ads from "../Components/FirstComponents/Ads";
import Subscribe from "../Components/FirstComponents/Subscribe";
import { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa6";
import Banner from "../Utils/Banner";


const imageOptions = [
  { value: 'image1', label: '1200 CALORI', src: '../assets/New-Hero/1200.jpg' },
  { value: 'image2', label: '1500 CALORI', src: '../assets/New-Hero/1500.jpg' },
  { value: 'image3', label: '1800 CALORI', src: '../assets/New-Hero/1800.jpg' },
  { value: 'image4', label: '2000 CALORI', src: '../assets/New-Hero/2000.jpg' },
];

export default function Dietchart() {

  const [selectedImage, setSelectedImage] = useState(imageOptions[0].src);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


  const handleImageChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = imageOptions.find(option => option.value === selectedValue);
    // setSelectedImage(selectedOption.src);

      setLoading(true);
      const img = new Image();
      img.src = selectedOption.src;
      img.onload = () => {
        setSelectedImage(selectedOption.src);
        setLoading(false);
      };

  };

  

  return (
    <>
      <div
        className="bg-contain bg-no-repeat bg-gray-200 "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className="mx-auto ">
          {/* <Hero /> */}

          {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
            DIET CHART
          </h1> */}

          <Banner title="DIET CHART" imageUrl="/assets/Second_Banner.png" />

          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32">
            {/* <MythsFacts /> */}
            {/* <HeartDisease /> */}

            <section className="mt-20 mb-10" style={{ marginTop: 40 }}>
              
            <div className="p-4">
      <div className="mb-4">
        <select
          onChange={handleImageChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          {imageOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-center">
        {loading ? (
          <div className="loader border-t-4 border-blue-500 border-solid rounded-full animate-spin w-16 h-16"></div>
        ) : (
          <img src={selectedImage} alt="Selected" className="mt-4 max-w-full h-auto rounded-md shadow-md" />
        )}
      </div>
    </div>

              



            </section>

            <a
                className="bg-[#2facaa]  hover:bg-[#91c8d0] text-white font-bold py-2 px-4 text-md rounded  mt-10"
                href="../assets/diet.pdf"
                target="_blank"
              >
                Download Diet Chart <FaDownload className="inline" size={15} />
              </a>


            <br />

            {/* <BlogAndArticle /> */}
            <Ads />
            <Subscribe />
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}
