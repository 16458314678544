import React from "react";
import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import FaqHypertensionData from "../Components/Common/FaqHypertensionData";
import Banner from "../Utils/Banner";

const FaqHypertension = () => {
  return (
    <>
      <div
        className="bg-contain bg-no-repeat bg-gray-200 "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
          FAQ's for Hypertension and CVD
        </h1> */}

        <Banner
          title="FAQ's for Hypertension and CVD"
          imageUrl="/assets/Second_Banner.png"
        />

        <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32">
          <FaqHypertensionData />
        </div>
        <NewFooter />
        <Copyright />
      </div>
    </>
  );
};

export default FaqHypertension;
