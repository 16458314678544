import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FirstLanding from "./Pages/FirstLanding";
import SecondLanding from "./Pages/SecondLanding";
import About from "./Pages/About";
import Testimonials from "./Pages/Testimonials";
import Contact from "./Pages/Contact";

import AOS from "aos";
import "aos/dist/aos.css";

// import Faq from "./Pages/Faq";

import Gallery from "./Pages/Gallery";
import FaqHypertension from "./Pages/FaqHypertension";
import FaqDiabetes from "./Pages/FaqDiabetes";
import UnderstandingDiabetes from "./Pages/UnderstandingDiabetes";
import UnderstandingHypertension from "./Pages/UnderstandingHypertension";
import YogaVideos from "./Pages/YogaVideos";
import Podcasts from "./Pages/Podcasts";
import Quiz from "./Pages/Quiz";
import LatestUpdates from "./Pages/LatestUpdates";
import OrganDonation from "./Pages/OrganDonation";
import OpVideos from "./Pages/OpVideos";
import BlogDetails from "./Pages/BlogDetails";
import Dietchart from "./Pages/Dietchart";
import { useEffect } from "react";
import NewHome from "./Pages/NewHome";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      {/* <div
        className="bg-gray-200 bg-no-repeat bg-contain"
        style={{ "background-image": "url('/assets/background.png')" }}
      >
        <Navbar />
        <div className="mx-auto ">
          <Hero />
          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14 xl:ml-32 xl:mr-32">
            <RiskFactor />
            <HypertensionVideo />
            <AboutHypertension />
            <HypertensionFact />
            <BlogAndArticle />
            <Ads />
            <DoctorView />
            <Subscribe />
          </div>
          <Footer />
          <Copyright />
        </div>
      </div> */}
      <BrowserRouter>
        <Routes>
          <Route>
            {/* <Route path="/" element={<FirstLanding />} /> */}
            <Route path="/" element={<NewHome />} />
            <Route path="/home" element={<NewHome />} />
            {/* <Route path="/home" element={<FirstLanding />} /> */}
            <Route path="/p2" element={<SecondLanding />} />
            <Route path="/introduction" element={<About />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact-us" element={<Contact />} />

            {/* <Route path="/faqs" element={<Faq />} /> */}
            <Route path="/gallery" element={<Gallery />} />

            <Route path="/faqs-diabetes" element={<FaqDiabetes />} />
            <Route path="/faqs-hypertension" element={<FaqHypertension />} />
            <Route
              path="/understanding-diabetes"
              element={<UnderstandingDiabetes />}
            />
            <Route
              path="/understanding-hypertension-and-cvd"
              element={<UnderstandingHypertension />}
            />
            <Route path="/dietchart" element={<Dietchart />} />
            <Route path="/yoga-videos" element={<YogaVideos />} />
            <Route path="/op-videos" element={<OpVideos />} />
            <Route path="/podcasts" element={<Podcasts />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/latest-updates" element={<LatestUpdates />} />
            <Route path="/organ-donation" element={<OrganDonation />} />
            <Route path="/details/:id" element={<BlogDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
