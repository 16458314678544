import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Ads from "../Components/FirstComponents/Ads";
import Subscribe from "../Components/FirstComponents/Subscribe";
import Banner from "../Utils/Banner";

function UnderstandingHypertension() {
  return (
    <>
      <div
        className='bg-contain bg-no-repeat bg-gray-100 '
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className='mx-auto '>
          {/* <Hero /> */}
          {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
            UNDERSTANDING ON HYPERTENSION & CVD
          </h1> */}

          <Banner
            title='UNDERSTANDING ON HYPERTENSION & CVD'
            imageUrl='/assets/Second_Banner.png'
          />

          <div className='mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32'>
            {/* <MythsFacts /> */}
            {/* <HeartDisease /> */}

            <section
              className='bg-white rounded-lg lg:p-20 md:p-10 gap-4 p-5 lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div className=' text-xs text-gray-600 xl:text-sm flex justify-center'>
                <img
                  data-aos='fade-down'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-1.jpg'
                  alt='Heart'
                  className='w-2/3 object-contain rounded-lg '
                />
              </div>
            </section>

            <section
              className='mt-5 flex flex-col lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div
                className=' w-full md:w-full'
                data-aos='fade-down'
                data-aos-easing='linear'
                data-aos-duration='300'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 uppercase flex justify-center'>
                  Symptoms of hypertension
                </h3>
                <p className=' text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                  Hypertension has few visible symptoms. However, a hypertensive
                  crisis can cause severe headaches, chest pain, dizziness,
                  difficulty breathing, nausea, vomiting, blurred vision or
                  other vision changes, anxiety, confusion, buzzing in the ears,
                  nosebleeds, abnormal heart rhythm.
                </p>
              </div>

              <div className='w-full flex md:w-full overflow-hidden justify-center'>
                <img
                  data-aos='zoom-in'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-2.jpg'
                  alt='Heart'
                  className='w-full lg:w-3/4 h-full object-contain'
                />
              </div>
            </section>

            <section
              className='mt-5 flex flex-col lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20 '
              style={{ marginTop: 40 }}
            >
              <div
                className=' w-full md:w-full'
                data-aos='fade-down'
                data-aos-easing='linear'
                data-aos-duration='300'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 uppercase flex justify-center'>
                  WHAT ARE THE REASONS FOR HYPERTENSION ?
                </h3>

                <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                  Hypertension typically develops over time and can be
                  attributed to unhealthy lifestyle choices. The causes can be
                  either modifiable risk factors (one can take measures to
                  change them) or non-modifiable risk factors (they cannot be
                  changed).
                  {/* <p>
                    <h2>Non-modifiable risk factors</h2>
                    1. Family history of Hypertension
                    2. Age is greater than 65
                    3. Co-existing disorders such as diabetes or renal disease
                  </p> */}
                  <div class='max-w-md mx-auto bg-white rounded mt-10 text-left'>
                    <h2 class='text-xl font-bold mb-4 uppercase'>
                      Non-modifiable Risk Factors
                    </h2>
                    <ul class='list-disc pl-5 text-justify'>
                      <li class='mb-2'>Family history of Hypertension</li>
                      <li class='mb-2'>Age is greater than 65</li>
                      <li class='mb-2'>
                        Co-existing disorders such as diabetes or renal disease
                      </li>
                    </ul>
                  </div>
                </p>
              </div>

              <div className='w-full flex md:w-full overflow-hidden justify-center'>
                <img
                  data-aos='zoom-in'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-3.jpg'
                  alt='Heart'
                  className='w-full lg:w-2/3 h-full object-contain'
                />
              </div>
            </section>

            <section
              className='mt-5 flex flex-col md:flex-row lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div
                className=' bg-white w-full md:w-full'
                data-aos='fade-down'
                data-aos-easing='linear'
                data-aos-duration='300'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 uppercase'>
                  The link between hypertension and cardiovascular (heart and
                  blood vessels) diseases
                </h3>
                <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                  Hypertension, if left untreated, can severely damage the
                  heart. High blood pressure can cause the arteries to harden,
                  which reduces blood and oxygen flow to the heart. This can
                  lead to chest discomfort, known as angina, or even a heart
                  attack. A heart attack happens when the supply of blood to the
                  heart is cut off, causing heart muscle cells to die due to a
                  lack of oxygen. The longer the blood flow is restricted, the
                  more damage the heart will suffer. This damage can result in
                  heart failure, where the heart cannot pump blood and oxygen
                  efficiently to vital organs, and an irregular heartbeat, which
                  can cause sudden death. Hypertension can also cause a stroke
                  by rupturing or blocking arteries that supply blood and oxygen
                  to the brain. However, effective treatment of hypertension
                  significantly reduces the risk of heart-related diseases.
                </p>
              </div>

              <div className='w-full flex md:w-full overflow-hidden'>
                <img
                  data-aos='zoom-in'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-4.jpg'
                  alt='Heart'
                  className='w-full h-full object-contain'
                />
              </div>
            </section>

            <section
              className='mt-5 flex flex-col-reverse lg:p-20 md:p-10 gap-4 p-8 mb-4  bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div className='w-full flex md:w-full overflow-hidden justify-center'>
                <img
                  data-aos='zoom-in'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-5.jpg'
                  alt='Heart'
                  className='w-full lg:w-3/4 h-full object-contain'
                />
              </div>
              <div
                data-aos='fade-down'
                data-aos-easing='linear'
                data-aos-duration='300'
                className='bg-white w-full md:w-full'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 uppercase flex justify-center'>
                  How to manage hypertension and cardiovascular disease risks?
                </h3>
                <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                  Hypertension, if left untreated, can severely damage the
                  heart. High blood pressure can cause the arteries to harden,
                  which reduces blood and oxygen flow to the heart. This can
                  lead to chest discomfort, known as angina, or even a heart
                  attack. A heart attack happens when the supply of blood to the
                  heart is cut off, causing heart muscle cells to die due to a
                  lack of oxygen. The longer the blood flow is restricted, the
                  more damage the heart will suffer. This damage can result in
                  heart failure, where the heart cannot pump blood and oxygen
                  efficiently to vital organs, and an irregular heartbeat, which
                  can cause sudden death. Hypertension can also cause a stroke
                  by rupturing or blocking arteries that supply blood and oxygen
                  to the brain. However, effective treatment of hypertension
                  significantly reduces the risk of heart-related diseases.
                </p>
              </div>
            </section>

            <section
              data-aos='fade-down'
              data-aos-easing='linear'
              data-aos-duration='300'
              className='flex flex-col md:flex-row gap-0 mb-4 lg:p-20 md:p-10 p-8 bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div className=''>
                <p className=' text-xs text-gray-600 xl:text-sm'>
                  <ul>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        1. Healthy eating:
                      </strong>
                      Adopt a balanced diet rich in fruits, vegetables, whole
                      grains, lean proteins, and low-fat dairy products. Limit
                      the consumption of processed foods, sodium (salt),
                      saturated and trans fats, and sugary beverages.
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        2. Regular physical activity:
                      </strong>
                      Engage in at least 150 minutes of moderate-intensity
                      aerobic exercise or 75 minutes of vigorous exercise per
                      week.
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        3. Weight management:
                      </strong>{" "}
                      Maintain a healthy weight by balancing calorie intake and
                      physical activity. Reducing even 5-10% of the body weight
                      can significantly lower blood pressure.
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        4. Salt reduction:
                      </strong>{" "}
                      Limit sodium intake to less than 2,300 mg per day.
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        {" "}
                        5. Moderating alcohol consumption:{" "}
                      </strong>{" "}
                      Moderate alcohol consumption by limiting one drink per day
                      for women and up to two drinks per day for men.
                    </li>
                  </ul>
                  <p className='mt-3 bg-slate-100 rounded-sm p-1'>
                    <strong>Smoking cessation:</strong> Smoking damages blood
                    vessels and increases the risk of heart disease and stroke.
                    Hence it is recommended to quit smoking and avoid
                    second-hand smoke.
                  </p>
                  <p className='mt-5'>
                    <strong className='text-lg text-[#2d95a5]'>
                      {" "}
                      Medication management
                    </strong>{" "}
                    <br />
                    1. Discuss with the clinician to determine the optimal
                    medications and understand the potential side effects or
                    interactions. <br />
                    2. Adhere to the prescribed medication adherence and
                    establish a routine for taking medications by setting
                    reminders. Consult the clinician before stopping or changing
                    the prescribed medication without consulting your healthcare
                    provider. <br />
                    3. Conduct regular check-ups to monitor the blood pressure
                    and adjust medications if necessary. <br />
                  </p>
                  <p className='mt-5'>
                    <strong className='text-lg text-[#2d95a5]'>
                      {" "}
                      Monitoring blood pressure
                    </strong>{" "}
                    <br />
                    1. Learn how to use a home blood pressure monitor correctly.
                    Keep a record of blood pressure regularly and seek medical
                    attention if there is any fluctuation. <br />
                    2. Understand the target blood pressure range and the
                    significance of reaching and maintaining it.
                  </p>

                  <p className='mt-5'>
                    <strong className='text-lg text-[#2d95a5]'>
                      Recognizing warning signs
                    </strong>{" "}
                    <br />
                    1. Learn to recognize symptoms that may indicate a
                    complication of hypertension or CVD such as chest pain,
                    shortness of breath, severe headaches, dizziness, or sudden
                    weakness. <br />
                    2. Seek immediate medical attention or call emergency
                    services if there is any concerning symptoms.
                  </p>

                  <p className='mt-5'>
                    <h2 className='fon-xl'>
                      {" "}
                      <strong className='text-lg text-[#2d95a5]'>
                        References
                      </strong>
                    </h2>
                    <br />
                    1. Research C for DE and. High Blood Pressure–Understanding
                    the Silent Killer. FDA [Internet]. 2021 Jan 21 [cited 2023
                    Jun 7]; Available from:
                    <a
                      href=' https://www.fda.gov/drugs/special-features/high-blood-pressure-understanding-silent-killer'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    2. Hypertension [Internet]. [cited 2023 Jun 7]. Available
                    from:{" "}
                    <a
                      href='https://www.who.int/health-topics/hypertension '
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    3. What is blood pressure and how is it measured? In:
                    InformedHealth.org [Internet] [Internet]. Institute for
                    Quality and Efficiency in Health Care (IQWiG); 2019 [cited
                    2023 Jun 7]. Available from:
                    <a
                      href='https://www.ncbi.nlm.nih.gov/books/NBK279251/  '
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    4. Desai AN. High Blood Pressure. JAMA. 2020 Sep
                    22;324(12):1254–5.
                    <br />
                    <br />
                    5. Understanding Blood Pressure Readings [Internet].
                    www.heart.org. [cited 2023 Jun 7]. Available from:
                    <a
                      href=' https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    6. Hypertension [Internet]. [cited 2023 Jun 7]. Available
                    from:
                    <a
                      href=' https://www.who.int/news-room/fact-sheets/detail/hypertension'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    7. Cardiovascular diseases (CVDs) [Internet]. [cited 2023
                    Jun 7]. Available from:
                    <a
                      href=' https://www.who.int/news-room/fact-sheets/detail/cardiovascular-diseases-(cvds)'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    8. Wu CY, Hu HY, Chou YJ, Huang N, Chou YC, Li CP. High
                    Blood Pressure and All-Cause and Cardiovascular Disease
                    Mortalities in Community-Dwelling Older Adults. Medicine
                    (Baltimore). 2015 Oct 30;94(47):e2160.
                    <br />
                    <br />
                    9. Hypertension [Internet]. World Heart Federation. [cited
                    2023 Jun 7]. Available from:
                    <a
                      href=' https://world-heart-federation.org/what-we-do/hypertension/'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    10. Fuchs FD, Whelton PK. High Blood Pressure and
                    Cardiovascular Disease. Hypertension. 2020 Feb;75(2):285–92.
                    <br />
                    <br />
                    11. Heart Disease and Stroke | CDC [Internet]. 2022 [cited
                    2023 Jun 7]. Available from:
                    <a
                      href=' https://www.cdc.gov/chronicdisease/resources/publications/factsheets/heart-disease-stroke.htm'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    12. Cubrilo-Turek M. Hypertension and Coronary Heart
                    Disease. EJIFCC. 2003 Jul 3;14(2):67–73.
                    <br />
                    <br />
                    13. Screening, Diagnosis, Assessment, and Management of
                    Primary Hypertension in Adults in India, Ministry of Health
                    & Family Welfare Government of India]. Available from:
                    <a
                      href=' https://nhm.gov.in/images/pdf/guidelines/nrhm-guidelines/stg/Hypertension_full.pdf'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    14. CDC High Blood Pressure Home [Internet]. Centers for
                    Disease Control and Prevention. 2023 [cited 2023 Jun 7].
                    Available from:
                    <a
                      href=' https://www.cdc.gov/bloodpressure/index.htm'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    <br />
                    <br />
                    15. Manage High Blood Pressure | cdc.gov [Internet]. Centers
                    for Disease Control and Prevention. 2023 [cited 2023 Jun 7].
                    Available from:
                    <a
                      href='https://www.cdc.gov/bloodpressure/manage.htm'
                      className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                  </p>
                </p>
              </div>
            </section>

            <Ads />
            <Subscribe />
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default UnderstandingHypertension;
