import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import BlogAndArticle from "../Components/FirstComponents/BlogAndArticle";
import Ads from "../Components/FirstComponents/Ads";

function OrganDonation() {
  return (
    <>
      <div
        className="bg-contain bg-no-repeat bg-gray-200 "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className="mx-auto ">
          {/* <Hero /> */}
          <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
            ORGAN DONATION
          </h1>
          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32">
            {/* <MythsFacts /> */}
            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: 40 }}
            >
              {/* <div className="border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-none bg-[#87c4cd] w-full md:w-1/3 overflow-hidden">
                    <img
                    src="../assets/HeartTwo.png"
                    alt="Heart"
                    className="w-full h-full object-fill"
                    />
                </div> */}

              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-2xl mb-2">
                  ORGAN DONATION AND PROTECTION: WHAT WE SHOULD KNOW
                </h1>
                <p className=" text-xs text-gray-600 xl:text-sm">
                  The deliberate act of donating organs or tissues from a living
                  or deceased individual for transplantation to another person
                  in need is referred to as organ donation.
                  <br />
                </p>
              </div>
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: 20 }}
            >
              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-2xl mb-2">
                  Shortage of organs in India
                </h1>
                <p className=" text-xs text-gray-600 xl:text-sm">
                  Organ shortage is a significant issue worldwide, and it is
                  particularly severe in many Asian countries, including India.
                  This shortage can be attributed to various factors, including
                  cultural beliefs, lack of awareness, limited infrastructure,
                  and challenges in organ procurement and allocation systems.
                  There is a significant shortage of organs in India in
                  comparison to the number of patients requiring transplants.
                  The statistics reveal a stark contrast:
                </p>
              </div>

              <div className="border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-none bg-[#87c4cd] w-full flex md:w-full overflow-hidden">
                <img
                  src="../assets/ord.png"
                  alt="Heart"
                  className="w-full h-full object-fill"
                />
              </div>
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: -18 }}
            >
              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <ul className="list-disc pl-5">
                  <li className="mb-2">
                    Around 1.8 lakh individuals encounter renal failure
                    annually, but only 6000 kidney transplants are performed.{" "}
                  </li>
                  <li className="mb-2">
                    Liver cancer or failure claims the lives of approximately
                    10-15% of the 2 lakh individuals who succumb to death in
                    India each year.{" "}
                  </li>
                  <li className="mb-2">
                    India requires an estimated 25 to 30 thousand liver
                    transplants annually, but merely around 1500 are carried
                    out.{" "}
                  </li>
                  <li className="mb-2">
                    Despite over 50,000 individuals experiencing heart failure
                    each year, only 10 to 15 heart transplants take place in
                    India.{" "}
                  </li>
                  <li className="mb-2">
                    While the demand for corneal transplants stands at 1 lakh,
                    only around 25,000 procedures are performed each year.{" "}
                  </li>
                </ul>
                <p className="mt-5">
                  Consistent efforts are needed to overcome challenges and
                  cultural barriers, leading to a significant change in the
                  country's organ donation scenario.
                </p>
              </div>
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: 20 }}
            >
              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg">
                  Individuals can make a valuable contribution to organ donation
                  by promoting both deceased organ donation and living organ
                  donation. Living organ donation is possible for certain organs
                  that the body can function with even in partial or single
                  form. The following organs can be donated by living donors:
                </p>
              </div>

              <div className="border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-none bg-[#87c4cd] w-full flex md:w-full overflow-hidden">
                <img
                  src="../assets/ord-2.png"
                  alt="Heart"
                  className="w-full h-full object-fill"
                />
              </div>
            </section>
            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: 20 }}
            >
              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg">
                  <strong>Deceased organ donation:</strong> After death, certain
                  organs can be donated easily, provided the appropriate
                  conditions are met. The organs need to be preserved and
                  transplanted into the recipient's body within a limited time
                  frame to maintain their viability. The organs that can be
                  donated after death and time duration for their retrieval are
                  listed below:
                </p>
              </div>

              <div className="border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-none bg-[#87c4cd] w-full flex md:w-full overflow-hidden">
                <img
                  src="../assets/ord-3.png"
                  alt="Heart"
                  className="w-full h-full object-fill"
                />
              </div>
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: -18 }}
            >
              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg">
                  <strong>Cornea donation</strong> is one of the simplest and
                  most common forms of organ donation. After death, the corneas,
                  which are the transparent front part of the eye, can be easily
                  donated to restore vision in individuals with corneal diseases
                  or injuries. <br />
                  <br />
                  Although <strong>kidney donation </strong> is regarded as
                  being simpler than other organ donations, it is still a
                  significant choice that necessitates a careful assessment of
                  the donor's health and well-being. A medical and psychological
                  evaluation is performed on potential kidney donors to
                  establish their appropriateness for donation. <br />
                  <br />
                  Additionally, different tissues can be given to subjects with
                  medical disorders. Skin, heart valves, blood arteries, bone,
                  and connective tissues are a few examples. Tissue donation can
                  have a big influence on recipients' lives and is typically
                  less invasive than organ donation.
                </p>
              </div>
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: 20 }}
            >
              <div className="border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-none bg-[#87c4cd] w-full flex md:w-1/2 overflow-hidden">
                <img
                  src="../assets/ord-4.png"
                  alt="Heart"
                  className="w-full h-full object-fill"
                />
              </div>

              {/* <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-2xl mb-2">
                Organ Donation Process
                </h1>
                
              </div> */}
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: 20 }}
            >
              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-2xl mb-2 text-center">
                  Brain death and organ donation
                </h1>
                <p>
                  A person who has brain death or brain stem death has no
                  prospect of recovery, as it causes irreversible injury to the
                  brain and stops brain activity. Once a person is declared
                  brain dead, they are technically dead, but their organs are
                  kept alive through artificial means. Organs from patients who
                  are deemed brain stem dead may be surgically removed for organ
                  donation.
                </p>

                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-2xl mb-2 text-center mt-5">
                  Registering for organ donation
                </h1>
                <p>
                  Registration as an organ donor is a personal decision and the
                  process of registering for organ donation varies depending on
                  the country. The general steps to be followed are briefed
                  below:
                </p>
                <div className="mx-auto bg-white rounded p-2 shadow-md mt-5">
                  <ul className="list-disc pl-5">
                    <li className="mb-4">
                      Registration: To determine the registration process,
                      individuals can check the country’s national organ
                      donation registry or a specific organization in charge of
                      organ donation.
                    </li>

                    <li className="mb-4">
                      Obtain information: Gather all the information before
                      registering, including name, contact information, date of
                      birth, medical history, and any preferences on organ
                      donation.
                    </li>

                    <li className="mb-4">
                      Online registration: If online registration is available,
                      visit the designated website, follow the provided
                      guidelines and fill in the required fields with accurate
                      information.
                    </li>

                    <li className="mb-4">
                      Paper registration: If online registration is not
                      feasible, communicate with the appropriate organ donation
                      organization or registry to obtain the registration forms.
                    </li>

                    <li className="mb-4">
                      Family conversation: This conversation ensures that the
                      family is aware of the choice and provides necessary
                      support.
                    </li>

                    <li>
                      Verification of registration: Following the completion of
                      the registration process, a letter or an email confirming
                      the registration may be received.
                    </li>
                  </ul>
                  <p className="p-2 mt-5 bg-slate-200 rounded-sm">
                    In India, there are numerous government websites and
                    organizations that emphasize and prioritize organ donation
                    and transplantation. Here are a few noteworthy ones:
                  </p>
                  <hr />

                  <div className="mx-auto bg-white rounded p-2 shadow-md mt-5">
                    <ul className="list-disc pl-5">
                      <li className="mb-4">
                        <strong>
                          National Organ and Tissue Transplant Organization
                          (NOTTO):
                        </strong>{" "}
                        This government organization is tasked with coordinating
                        organ and tissue transplantation efforts in India. On
                        their website, they provide details about organ
                        allocation, transplant regulations, and organ donation.
                        The details can be found at http://notto.gov.in/ .
                      </li>

                      <li className="mb-4">
                        <strong>
                          State Organ and Tissue Transplant Organization
                          (SOTTO):
                        </strong>{" "}
                        Each state in India has a separate SOTTO, which is in
                        charge of regulating state-level organ donation and
                        transplantation procedures. For example, Madhya Pradesh
                        SOTTO's website can be found at https://sotto.mp.gov.in/{" "}
                      </li>

                      <li className="mb-4">
                        <strong>
                          Regional Organ and Tissue Transplant Organization
                          (ROTTO):
                        </strong>{" "}
                        India is divided into several regions, and each region
                        has its own ROTTO. For instance, Mumbai, Maharashtra
                        ROTTO’s website can be found at
                        https://www.rottosottokem.in/about{" "}
                      </li>
                    </ul>
                    <p className="p-2 mt-5 bg-slate-200 rounded-sm">
                      Please be aware that while these websites provide valuable
                      information on organ donation in India, it is advisable to
                      consult doctors for the most accurate and up-to-date
                      details on procedures and regulations regarding organ
                      donation in your specific area.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row gap-3 mb-5"
              style={{ marginTop: 20 }}
            >
              <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-2xl mb-2 text-center mt-5">
                  Organ Protection
                </h1>
                <p className=" text-xs text-gray-600 xl:text-sm">
                  Organ transplantation is a complex and costly medical
                  procedure. It involves not only the transplant surgery itself
                  but also post-transplant care, which includes lifelong
                  immunosuppressant medications and frequent medical check-ups.
                  Reducing the demand for transplantation can have a substantial
                  positive impact on healthcare costs, both for individuals and
                  healthcare systems. Hence it is important to enhance organ
                  protection efforts, which include focusing on preventive
                  measures and lifestyle changes. Promoting healthier habits,
                  regular medical check-ups, and early disease detection can
                  help maintain organ health and overall well-being.
                </p>
              </div>

              <div className="border rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-none bg-[#87c4cd] w-full flex md:w-full overflow-hidden">
                <img
                  src="../assets/ord-5.png"
                  alt="Heart"
                  className="w-full h-full object-fill"
                />
              </div>
            </section>
            <div className="border rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg bg-white w-full md:w-full p-4">
              <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg w-full">
                By adopting these practices, you can promote the longevity and
                proper function of your vital organs, contributing to a
                healthier, happier life.
              </p>

              <div className="max-w-full mx-auto bg-white rounded p-6 shadow-md">
                <h2 className="text-2xl font-bold mb-4">References</h2>

                <ul className="list-none">
                  <li className="mb-4">
                    <p className="text-blue-500">
                      General Information on Living Donation [Internet].
                      National Kidney Foundation. 2015 [cited 2023 Jun 12].
                    </p>
                    <a
                      href="https://www.kidney.org/transplantation/livingdonors/general-information-living-donation"
                      className="text-blue-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Available from:
                      https://www.kidney.org/transplantation/livingdonors/general-information-living-donation
                    </a>
                  </li>

                  <li className="mb-4">
                    <p className="text-blue-500">
                      Home [Internet]. Organ Retrieval Banking Organization.
                      [cited 2023 Jun 12].
                    </p>
                    <a
                      href="https://orbo.org.in/"
                      className="text-blue-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Available from: https://orbo.org.in/
                    </a>
                  </li>

                  <li className="mb-4">
                    <p className="text-blue-500">
                      Living Organ Donation | organdonor.gov [Internet]. [cited
                      2023 Jun 12].
                    </p>
                    <a
                      href="https://www.organdonor.gov/learn/process/living-donation"
                      className="text-blue-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Available from:
                      https://www.organdonor.gov/learn/process/living-donation
                    </a>
                  </li>

                  <li className="mb-4">
                    <p className="text-blue-500">
                      Moritsugu KP. The Power of Organ Donation to Save Lives
                      Through Transplantation. Public Health Rep.
                      2013;128(4):245–6.
                    </p>
                  </li>

                  <li className="mb-4">
                    <p className="text-blue-500">
                      NOTTO: National Organ & Tissue Transplant Organisation
                      [Internet]. [cited 2023 Jun 12].
                    </p>
                    <a
                      href="https://notto.mohfw.gov.in/"
                      className="text-blue-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Available from: https://notto.mohfw.gov.in/
                    </a>
                  </li>

                  <li className="mb-4">
                    <p className="text-blue-500">
                      State Organ & Tissue Transplant Organization [Internet].
                      [cited 2023 Jun 15].
                    </p>
                    <a
                      href="https://sotto.mp.gov.in/"
                      className="text-blue-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Available from: https://sotto.mp.gov.in/
                    </a>
                  </li>

                  <li>
                    <p className="text-blue-500">
                      ROTTO-SOTTO, Mumbai [Internet]. [cited 2023 Jun 15].
                    </p>
                    <a
                      href="https://www.rottosottokem.in/about"
                      className="text-blue-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Available from: https://www.rottosottokem.in/about
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <BlogAndArticle />
            <Ads />
            {/* <Subscribe /> */}
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default OrganDonation;
