import React from "react";
import { FaRegStar, FaStar } from "react-icons/fa"; // Import the star icons from react-icons

const Rating = ({ rating, className }) => {
  const maxRating = 5; // Maximum rating scale assumed

  // Generate stars based on the rating
  const stars = Array.from({ length: maxRating }, (_, index) => {
    if (index < rating) {
      // Render a filled star if index is less than rating
      return (
        <FaStar
          key={index}
          color="#2D95A5"
          size={20}
          style={{ marginRight: "2px" }} // Add spacing between stars
        />
      );
    } else {
      // Render an outlined star if index is greater than or equal to rating
      return (
        <FaRegStar
          key={index}
          color="#2D95A5"
          size={20}
          style={{ marginRight: "2px" }} // Add spacing between stars
        />
      );
    }
  });

  return <div className={`flex ${className}`}>{stars}</div>;
};

export default Rating;
