import React, { useState } from "react";
import Accordion from "./Accordion";

const disclosureData = [
  {
    id: 1,
    title: "Cardiovascular System",
    content: `Diabetes and hypertension elevate the risk of heart disease by promoting cholesterol buildup, damaging blood vessels, and causing structural and functional changes in the heart. They also affect platelets, further increasing cardiovascular disease risk. `,
  },
  {
    id: 2,
    title: "Kidneys",
    content: `Both diabetes and hypertension can lead to chronic kidney disease. In diabetes (diabetic nephropathy), it damages kidney blood vessels, potentially causing failure, requiring dialysis or transplant. Hypertension can also harm kidney vessels, reducing function `,
  },
  {
    id: 3,
    title: "Eyes",
    content: `Both conditions can damage vision. Retinopathy occurs when the retina's blood vessel walls thicken and narrow, potentially causing vision problems or blindness. Blurry vision is an early diabetes symptom. `,
  },
  {
    id: 4,
    title: "Nerves",
    content: `Diabetic neuropathy causes numbness, tingling, and pain in the extremities. High blood pressure raises stroke risk due to artery blood clotting, obstructing blood flow and damaging artery tissues. `,
  },
  {
    id: 5,
    title: "Feet and skin",
    content: `Poor blood circulation and nerve damage can lead to foot ulcers, reduced healing process and sensation, increased risk of infection, and in severe cases, amputation. Additionally, diabetic patients often experience extremely dry skin due to damage to their small blood vessels and nerves. `,
  },
  {
    id: 6,
    title: "Gastrointestinal Tract",
    content: `Diabetes can slow down the movement of food through the stomach and intestines. This leads to dehydration, malnutrition, and also unpredictable changes in blood sugar levels. `,
  },
];

const DiabetesHypertension = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <section className="mx-auto xl:p-10 xl:mr-44 xl:ml-44 -mt-16 p-6">
      <h1
        className="text-textColor text-2xl lg:text-4xl font-inter font-semibold uppercase lg:w-[1000px]"
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
        crossOrigin="anonymous"
      >
        Know more about Diabetes & Hypertension
      </h1>
      <p className="text-textColor/75 mt-3 mb-3 text-md text-justify font-inter font-normal ">
        Diabetes and hypertension, which have shared causes and risk factors,
        can lead to long-term complications and associated organ damage. Organs
        affected by Diabetes and Hypertension
      </p>

      <div className="grid grid-cols-1 lg:grid-cols-2 mt-20 gap-10">
        <div
          className="col-span-1 relative"
          data-aos="fade-leftt"
          data-aos-easing="linear"
          data-aos-duration="1000"
          crossOrigin="anonymous"
        >
          <div className="border-2 border-white">
            <img
              src="/assets/New-Hero/about-2.png"
              alt="Diabetes and Hypertension"
              className="object-cover w-full h-full"
            />
          </div>

          {/* <div className="flex absolute -bottom-14 right-0">
            <div className="bg-textColor w-[150px] h-[140px] flex flex-col items-center justify-center">
              <h1 className="text-white text-4xl font-inter font-semibold uppercase">
                46+
              </h1>
              <p className="text-white text-base text-justify font-inter font-normal ">
                Doctors
              </p>
            </div>

            <div className="bg-primary w-[150px] h-[140px] flex flex-col items-center justify-center">
              <h1 className="text-white text-4xl font-inter font-semibold uppercase">
                7+
              </h1>
              <p className="text-white text-base text-justify font-inter font-normal ">
                world office
              </p>
            </div>
          </div> */}

        </div>

        <div
          className="col-span-1 mt-10"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1000"
          crossOrigin="anonymous"
        >
          {disclosureData.map((item, index) => (
            <Accordion
              key={item.id}
              item={item}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiabetesHypertension;
