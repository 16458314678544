import Ads from "../Components/FirstComponents/Ads";
import DoctorView from "../Components/FirstComponents/DoctorView";
import Hero from "../Components/FirstComponents/Hero";
import NewNavbar from "../Components/Common/NewNavbar";
import RiskFactor from "../Components/FirstComponents/RiskFactor";
import HypertensionVideo from "../Components/FirstComponents/HypertensionVideo";
import AboutHypertension from "../Components/FirstComponents/AboutHypertension";
import HypertensionFact from "../Components/FirstComponents/HypertensionFact";
import Subscribe from "../Components/FirstComponents/Subscribe";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";

function FirstLanding() {
  return (
    <>
      <div
        className="bg-contain bg-no-repeat bg-gray-200"
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className="mx-auto ">
          <Hero />
          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32">
            <RiskFactor />
            <HypertensionVideo />
            <AboutHypertension />
            <HypertensionFact />
            {/* <BlogAndArticle /> */}
            <Ads />
            <DoctorView />
            <Subscribe />
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default FirstLanding;
