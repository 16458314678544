import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { FaCircleArrowRight } from "react-icons/fa6";

const ContactForm = () => {
  const [saving, setSaving] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone no is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (saving) {
      return;
    }
    setSaving(true);

    try {
      const res = await fetch(
        process.env.REACT_APP_BASE_API_URL + "/enquiries",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      const data = await res.json();
      console.log(data);

      if (res.status !== 200) {
        const error = new Error(data.error || "Failed to submit enquiry");
        throw error;
      }

      resetForm();
      toast.success("Thank you for your enquiry!");
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false); // Always set saving state back to false
    }
  };

  return (
    <>
      <ToastContainer />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="mb-4 grid grid-cols-1 lg:grid-cols-2 lg:gap-6 space-y-3 lg:space-y-0">
            <div>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                className="w-full font-inter font-medium text-sm p-2 py-3  border bg-transparent border-white/75 rounded-md focus:outline-none placeholder:text-white/75"
              />
              <ErrorMessage
                name="name"
                component="p"
                className="mt-1 text-xs text-white"
              />
            </div>

            <div>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                className="w-full font-inter font-medium text-sm p-2 py-3  border bg-transparent border-white/75 rounded-md focus:outline-none placeholder:text-white/75"
              />
              <ErrorMessage
                name="email"
                component="p"
                className="mt-1 text-xs text-white"
              />
            </div>

            <div>
              <Field
                type="text"
                id="phone"
                name="phone"
                placeholder="Your Phone"
                className="w-full font-inter font-medium text-sm p-2 py-3  border bg-transparent border-white/75 rounded-md focus:outline-none placeholder:text-white/75"
              />
              <ErrorMessage
                name="phone"
                component="p"
                className="mt-1 text-xs text-white"
              />
            </div>

            {/* <div>
              <Field
                type="date"
                id="date"
                name="date"
                placeholder="mm/dd/yyyy"
                className="w-full font-inter font-medium text-sm p-2 py-3 border bg-transparent border-white/75 rounded-md focus:outline-none placeholder:text-white/75"
              />
              <ErrorMessage
                name="date"
                component="p"
                className="mt-1 text-xs text-white"
              />
            </div> */}
          </div>

          <div className="mb-6">
            <Field
              as="textarea"
              id="message"
              name="message"
              rows="4"
              placeholder="Your message"
              className="w-full font-inter font-medium text-sm p-2 py-3 border bg-transparent border-white/75 rounded-md focus:outline-none placeholder:text-white/75"
            />
            <ErrorMessage
              name="message"
              component="p"
              className="mt-1 text-xs text-white"
            />
          </div>

          {/* Submit Button */}

          <div className="flex items-center justify-start">
            <button
              type="submit"
              className="bg-transparent font-inter text-base font-semibold hover:bg-white text-white border-white rounded-full  hover:text-primary py-2 px-4 border-2 focus:outline-none transition duration-300 ease-in-out flex items-center gap-2"
              disabled={saving} // Disable the button while saving
            >
              {saving ? "Sending..." : "Send"} <FaCircleArrowRight />
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ContactForm;
