import Hero from "../Components/SecondComponents/Hero";
import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Question from "../Components/SecondComponents/Question";
import MythsFacts from "../Components/SecondComponents/Myths&Facts";
import BlogAndArticle from "../Components/FirstComponents/BlogAndArticle";
import HeartDisease from "../Components/SecondComponents/HeartDisease";
import Ads from "../Components/FirstComponents/Ads";
import Subscribe from "../Components/FirstComponents/Subscribe";

function SecondLanding() {
  return (
    <>
      <div
        className="bg-contain bg-no-repeat bg-gray-200 "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className="mx-auto ">
          <Hero />
          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32">
            <Question />
            <MythsFacts />
            <HeartDisease />
            <BlogAndArticle />
            <Ads />
            <Subscribe />
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default SecondLanding;
