import React, { useEffect, useState } from "react";
import Rating from "../../Utils/Rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const PatientTestimonial = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const callTestimonials = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/testimonials?type=2",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log(testimonials);

        const data = await res.json();
        console.log(testimonials);
        console.log(data);
        setTestimonials(data);
        console.log(testimonials);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callTestimonials();
  }, []);

  const toggleExpand = (index) => {
    setTestimonials((prevTestimonials) =>
      prevTestimonials.map((testimonial, idx) =>
        idx === index
          ? { ...testimonial, expanded: !testimonial.expanded }
          : testimonial
      )
    );
  };

  return (
    <>
      {testimonials.length > 0 && (
        <section className="bg-primary mb-1">
          <div className="mx-auto xl:mr-44 xl:ml-44 lg:mt-1 p-6">
            <h1
              className="text-white text-2xl lg:text-4xl font-inter font-semibold mt-5"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="1000"
              crossOrigin="anonymous"
            >
              What does patients say about Mission Organ Protection?
            </h1>

            <div className="relative">
              <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                navigation={{
                  nextEl: ".swiper-button-next-custom",
                  prevEl: ".swiper-button-prev-custom",
                }}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="">
                  {testimonials
                    .filter((testimonial) => testimonial.type == 2)
                    .map((testimonial, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className="border bg-white rounded-3xl p-6 w-full mt-10 lg:mt-20 mb-20 "
                          data-aos="fade-left"
                          data-aos-easing="linear"
                          data-aos-duration="1000"
                          crossOrigin="anonymous"
                        >
                          <div className="flex items-center gap-5">
                            <img
                              src={
                                testimonial.avatar ||
                                "https://via.placeholder.com/150"
                              }
                              alt="avatar"
                              className="w-14 h-14 rounded-full"
                            />
                            <div className="grid">
                              <h5 className="text-textColor font-inter font-semibold text-lg">
                                {testimonial.name}
                              </h5>
                              <span className="text-base text-textColor/75 font-inter font-normal">
                                {/* {testimonial.designation} */}
                                Patient
                              </span>
                            </div>
                          </div>

                          <div className="mt-4">
                            <Rating
                              rating={testimonial.rating}
                              className="justify-start"
                            />
                          </div>

                          {/* <div className="flex-grow overflow-y-auto">
                          <p
                            className="mb-4 text-justify text-gray-800"
                            dangerouslySetInnerHTML={{
                              __html: testimonial.content,
                            }}
                          ></p>
                        </div> */}

                          <div className="overflow-hidden mt-4">
                            <p
                              className={`mb-4 text-justify font-inter font-normal text-base text-textColor/75 ${
                                testimonial.expanded ? "" : "line-clamp-5"
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: testimonial.content,
                              }}
                            ></p>
                            {testimonial.content.length > 250 && (
                              <button
                                className="text-navbar hover:underline focus:outline-none"
                                onClick={() => toggleExpand(index)}
                              >
                                {testimonial.expanded
                                  ? "Read less"
                                  : "Read more..."}
                              </button>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </div>
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PatientTestimonial;
