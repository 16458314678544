import React from "react";

const Hero = () => {
  const BoxData = [
    {
      id: 1,
      roundedClasses:
        "rounded-tl-none rounded-tr-lg rounded-bl-lg rounded-br-lg",
      text: "Lorem ipsum Lorem ipsum Lorem ipsum",
    },
    {
      id: 2,
      roundedClasses:
        "rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg",
      text: "Lorem ipsum Lorem ipsum Lorem ipsum",
    },
    {
      id: 3,
      roundedClasses:
        "rounded-tl-lg rounded-tr-lg rounded-bl-none rounded-br-lg",
      text: "Lorem ipsum Lorem ipsum Lorem ipsum",
    },
    {
      id: 4,
      roundedClasses:
        "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-none",
      text: "Lorem ipsum Lorem ipsum Lorem ipsum",
    },
  ];

  return (
    <>
      <div className="md:order-2">
        <img
          src="../assets/Second_Banner.png"
          alt="Second Banner"
          className="mx-auto max-w-full absolute"
        />

        <div className="relative pl-6 lg:pl-12 xl:pl-32 p-10" >
          {/* <h1 className="text-lg xl:text-2xl font-light">MISSION ORGAN</h1> */}
          <h3 className="text-5xl md:text-5xl xl:text-4xl font-medium text-[#2d95a5]">
             MISSION 
          </h3>
          <h2 className="text-5xl md:text-7xl xl:text-6xl font-medium text-[#2d95a5]">
          ORGAN PROTECTION
          </h2>

          <div className="flex">
            {/* <h3 className="text-lg md:text-xl xl:text-2xl font-light">
              is as strong as its{" "}
            </h3> */}
            {/* <span className="text-5xl ml-10 md:text-6xl lg:text-6xl xl:text-8xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#d92633] via-[#62497b] to-[#295195] font-rougeScript">
              Protection
            </span> */}
            
          </div>

        </div>
      </div>
    </>
  );
};

export default Hero;
