import React, { useEffect, useState } from "react";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import Modal from "react-modal"; // Import react-modal
import { Formik, Form, Field, ErrorMessage } from "formik"; // Import Formik components
import * as Yup from "yup"; // Import Yup for validation
import { AiOutlineClose } from "react-icons/ai";

const Question = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for the modal
  const totalQuestions = questions.length;
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // State to track if the selected answer is correct
  const [errorMessage, setErrorMessage] = useState("");
  const [showAnswers, setShowAnswers] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const validationSchema = Yup.object({
    customerName: Yup.string().required(
      "Your Initial is Required to Generate Results"
    ),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    // phone: Yup.string().required("Phone is required"),
  });

  const handleSubmit = (values) => {
    // Calculate the number of correct and wrong answers
    const totalQuestions = questions.length;
    const correctAnswers = answers.filter((answer) => answer.isCorrect).length;
    const wrongAnswers = totalQuestions - correctAnswers;

    // Calculate the result percentage
    const resultPercentage = (correctAnswers / totalQuestions) * 100;

    // Prepare the data to send in the POST request
    const postData = {
      customer_name: values.customerName,
      email: values.email,
      phone: values.phone,
      quiz_category_id: questions[0].quiz_category_id,
      correct_ans: correctAnswers,
      wrong_ans: wrongAnswers,
      result: resultPercentage,
    };

    // Make your POST API call with postData
    // Replace "your-api-endpoint" with the actual endpoint
    fetch(process.env.REACT_APP_BASE_API_URL + "/quiz_responses", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          // If the response status is OK (200), close the modal
          setErrorMessage("");
          setShowAnswers(true);
          // closeModal();
        } else {
          setErrorMessage("Something went wrong!");
        }
      })

      .catch((error) => {
        setErrorMessage("Something went wrong!");
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const callQuestions = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/quiz_questions",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);
        setQuestions(data);
        setCurrentQuestion(data[0]);
        console.log(questions);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callQuestions();
  }, []);

  const handleNextQuestion = () => {
    setSelectedAnswerIndex(null);
    // Check if there are more questions
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentQuestion(questions[currentQuestionIndex + 1]);
    } else {
      // Handle quiz completion or redirect to the next step
      // You can implement your logic here
    }
  };

  const handleAnswerClick = (ansIndex, selectedAnswer) => {
    const isCorrect = ansIndex == currentQuestion.correct_ans;
    // console.log(ansIndex);
    // console.log(currentQuestion.correct_ans);
    // console.log(isCorrect);
    // Store the selected answer for the current question
    setIsAnswerCorrect(isCorrect);

    setAnswers([
      ...answers,
      {
        question: currentQuestion.question,
        answer: selectedAnswer,
        correctAnswer: currentQuestion[`ans${currentQuestion.correct_ans}`],
        isCorrect: isCorrect,
      },
    ]);
    setSelectedAnswerIndex(ansIndex);
    // handleNextQuestion();
  };

  const handleQuizCompletion = () => {
    openModal();
  };

  return (
    <>
      <section className='border-2 border-[#2d95a5] p-4 pr-4 pl-4 rounded-lg mt-4 bg-white bg-opacity-80 font-ubuntu'>
        <div className='flex flex-col gap-8 md:flex-row'>
          <div className='w-full md:w-1/2 '>
            <img
              src='../assets/LayerTwo.png'
              alt='Layer Two'
              data-aos='fade-left'
              data-aos-easing='linear'
              data-aos-duration='300'
            />
          </div>

          <div className='flex flex-col justify-center w-full md:w-1/2'>
            <h1 className='text-[#2d95a5] font-semibold text-lg lg:text-xl xl:text-2xl mb-4'>
              {currentQuestion ? currentQuestion.question : ""}
            </h1>
            {/* Render answer options based on currentQuestion */}
            {currentQuestion && (
              <div className='grid grid-cols-1 gap-2 text-center lg:grid-cols-2 md:flex-row'>
                {[1, 2, 3, 4].map((ansIndex) => (
                  <div
                    key={ansIndex}
                    data-aos='fade-left'
                    data-aos-easing='linear'
                    data-aos-duration='1000'
                    className='border border-[#2d95a5] rounded-lg w-full h-16 flex items-center justify-center cursor-pointer'
                    onClick={() =>
                      handleAnswerClick(
                        ansIndex,
                        currentQuestion[`ans${ansIndex}`]
                      )
                    }
                  >
                    {currentQuestion[`ans${ansIndex}`]}
                    {selectedAnswerIndex === ansIndex && (
                      <BsCheckCircleFill className='text-[#2d95a5] ml-2' />
                    )}
                  </div>
                ))}
              </div>
            )}

            {currentQuestionIndex < totalQuestions - 1 && (
              <>
                <button
                  disabled={selectedAnswerIndex === null}
                  onClick={handleNextQuestion}
                  className={`bg-[#2d95a5] text-white py-2 px-4 rounded-md mt-4`}
                  style={{
                    backgroundColor: selectedAnswerIndex === null ? "#ccc" : "",
                    color: selectedAnswerIndex === null ? "#999" : "",
                    cursor:
                      selectedAnswerIndex === null ? "not-allowed" : "pointer",
                  }}
                >
                  Next
                </button>
              </>
            )}
            {currentQuestionIndex == totalQuestions - 1 && (
              <button
                disabled={selectedAnswerIndex === null}
                onClick={handleQuizCompletion}
                className={`bg-[#2d95a5] text-white py-2 px-4 rounded-md mt-4`}
                style={{
                  backgroundColor: selectedAnswerIndex === null ? "#ccc" : "",
                  color: selectedAnswerIndex === null ? "#999" : "",
                  cursor:
                    selectedAnswerIndex === null ? "not-allowed" : "pointer",
                }}
              >
                Finish Quiz
              </button>
            )}

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel='Quiz Results'
              className='mx-4 mt-4 mb-4 overflow-y-auto sm:mx-8 md:mx-16 lg:mx-32 xl:mx-64 '
            >
              <div className='max-h-[90vh] p-6 overflow-y-auto bg-white rounded-lg shadow-md relative'>
                <button
                  onClick={closeModal}
                  className='absolute text-gray-600 top-4 right-4 hover:text-gray-800'
                >
                  <AiOutlineClose size={24} />
                </button>
                {showAnswers ? (
                  <>
                    {" "}
                    <h1 className='text-2xl font-bold mb-4 text-[#2d95a5]'>
                      Quiz Results
                    </h1>
                    <div>
                      {answers.map((answer, index) => (
                        <div key={index} className='mb-4'>
                          <p className='font-semibold'>
                            Question: {answer.question}
                          </p>
                          <p>
                            Answer: {answer.answer}{" "}
                            {answer.isCorrect ? (
                              <BsCheckCircleFill className='inline text-green-500' />
                            ) : (
                              <BsXCircleFill className='inline text-red-500' />
                            )}
                          </p>
                          {!answer.isCorrect && (
                            <p className='text-red-500'>
                              Correct Answer: {answer.correctAnswer}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                    {/* close modal button */}
                    <button
                      onClick={closeModal}
                      className='bg-[#2d95a5] text-white py-2 px-4 rounded-md mt-4 hover:bg-[#1f7a8c]'
                    >
                      Done
                    </button>
                  </>
                ) : (
                  <Formik
                    initialValues={{
                      customerName: "",
                      email: "",
                      phone: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className='mb-4'>
                        <h1 className='text-2xl font-bold mb-4 text-[#2d95a5]'>
                          Please enter your details to view the results
                        </h1>
                      </div>
                      <div className='flex flex-col'>
                        <label
                          htmlFor='customerName'
                          className='font-semibold text-gray-700'
                        >
                          Your Initial
                        </label>
                        <Field
                          type='text'
                          id='customerName'
                          name='customerName'
                          className='w-full p-2 border border-gray-300 rounded-lg form-input'
                        />
                        <ErrorMessage
                          name='customerName'
                          component='div'
                          className='text-red-500'
                        />
                      </div>

                      <div className='flex flex-col'>
                        <label
                          htmlFor='email'
                          className='font-semibold text-gray-700'
                        >
                          {/* Your Email */}
                        </label>
                        <Field
                          type='hidden'
                          id='email'
                          name='email'
                          value='test@gmail.com'
                          className='w-full p-2 border border-gray-300 rounded-lg form-input'
                        />
                        <ErrorMessage
                          name='email'
                          component='div'
                          className='text-red-500'
                        />
                      </div>

                      <div className='flex flex-col'>
                        <label
                          htmlFor='phone'
                          className='font-semibold text-gray-700'
                        >
                          {/* Your Phone No. */}
                        </label>
                        <Field
                          type='hidden'
                          id='phone'
                          name='phone'
                          value='0000000000'
                          className='w-full p-2 border border-gray-300 rounded-lg form-input'
                        />
                        <ErrorMessage
                          name='phone'
                          component='div'
                          className='text-red-500'
                        />
                      </div>

                      <button
                        type='submit'
                        className='bg-[#2d95a5] text-white py-2 px-4 rounded-md mt-4 hover:bg-[#1f7a8c]'
                      >
                        View Results
                      </button>
                      <span className='ml-5 text-red-500'>{errorMessage}</span>
                    </Form>
                  </Formik>
                )}
              </div>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
};

export default Question;
