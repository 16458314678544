import React from "react";
import NewFooter from "../Components/Common/NewFooter";
import NewNavbar from "../Components/Common/NewNavbar";
import Hero from "../Components/NewHome/Hero";
import NewCopyright from "../Components/Common/NewCopyright";
import AboutUs from "../Components/NewHome/AboutUs";
import OrgansAffected from "../Components/NewHome/OrgansAffected";
import OrganVideos from "../Components/NewHome/OrganVideos.jsx";
import InfoCard from "../Components/NewHome/InfoCard.jsx";
import DiabetesHypertension from "../Components/NewHome/DiabetesHypertension/DiabetesHypertension.jsx";
import HypertensionFacts from "../Components/NewHome/HypertensionFacts.jsx";
import PatientTestimonial from "../Components/NewHome/PatientTestimonial.jsx";
import ContactUs from "../Components/NewHome/ContactUs/ContactUs.jsx";

const NewHome = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <NewNavbar />
        <div className=" flex-1">
          <Hero />
          <AboutUs />

          <OrgansAffected />
          
          <DiabetesHypertension />
          <HypertensionFacts />


         
          {/* <OrganVideos /> */}
          {/* <InfoCard />  */}
          
          <ContactUs />
          <PatientTestimonial />

        </div>
        <NewFooter />
        <NewCopyright />
      </div>
    </>
  );
};

export default NewHome;
