import React, { useEffect, useState } from "react";
import Disclosure from "../../Utils/Disclosure";

const FAQs = () => {
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    const callFaqData = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/faqs?faq_category_id=1",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);
        setFaqData(data);
        console.log(faqData);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callFaqData();
  }, []);

  return (
    <>
      <div className=''>
        <div className='max-w-md p-8 mx-auto'>
          <div className='relative flex items-center'>
            {/* <input
              type="text"
              className="w-full h-10 px-8 pr-10 text-sm bg-white border-2 border-gray-300 rounded-full focus:outline-none"
              placeholder="Search..."
            /> */}
            {/* <button type="submit" className="absolute top-0 right-0 mt-3 mr-4">
              <svg
                className="w-4 h-4 text-gray-600"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M21 21l-6-6M3 9a9 9 0 1118 0 9 9 0 01-18 0z"></path>
              </svg>
            </button> */}
          </div>
        </div>

        <div
          className='grid grid-cols-1 gap-0 mx-2 mt-0 lg:mx-10'
          data-aos='zoom-in'
          data-aos-easing='linear'
          data-aos-duration='300'
        >
          {faqData.map((item, index) => (
            <Disclosure
              key={index}
              title={item.question}
              content={item.answer}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQs;
