import React from "react";

const NewCopyright = () => {
  return (
    <>
      <section className="bg-primary ">
        <div className="text-white flex items-center justify-center p-4">
          <span>&copy; 2024 Micro Labs Ltd. All rights reserved.</span>
        </div>
      </section>
    </>
  );
};

export default NewCopyright;
