import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BlogAndArticle = () => {
  const [contents, setContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Change this value according to your requirement

  useEffect(() => {
    const callContents = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/contents",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const data = await res.json();
        setContents(data);
      } catch (err) {
        console.log(err);
      }
    };
    callContents();
  }, []);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = contents.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {contents.length > 0 && (
        <section className="font-ubuntu -mt-20" >
          <div className="flex flex-col justify-between pt-4 pb-4 md:flex-row">
            <h1
              className="text-[#2d95a5] font-bold text-lg md:text-2xl mb-1 uppercase"
              data-aos="fade-left"
            >
              Blogs and  Podcasts
            </h1>
            <Link
              to="/op-videos"
              className="bg-[#2d95a5] hover:bg-[#91c8d0] text-white font-bold py-2 px-4 text-sm rounded-full right-0 text-center"
            >
              View All
            </Link>
          </div>

          <div className="flex flex-col justify-center">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
              {currentItems.map((content, index) => (
                <div
                  data-aos="fade-left"
                  key={index}
                  className=" overflow-hidden border  rounded-md bg-slate-50"
                >
                  <div className="m-3 overflow-hidden rounded-md cursor-pointer ">
                    <img
                      // data-aos="fade-up"
                      crossOrigin="anonymous"
                      className="object-contain w-full "
                      src={content.thumbnail}
                      alt={`Card ${index + 1}`}
                    />
                  </div>
                  <div className="px-3 text-[#2d95a5]">
                    <div className="text-base font-bold">
                      <Link
                        data-aos="fade-left"
                        to={`/details/${content.id}`}
                        className=" hover:bg-[#91c8d0 font-bold text-sm"
                      >
                        {content.title}
                      </Link>
                    </div>
                  </div>

                  <div
                    className="flex items-center justify-between px-2 py-4"
                    data-aos="fade-left"
                  >
                    <div className="text-xs font-normal">
                      {new Date(content.createdAt).toDateString()}
                    </div>

                    <div className="">
                      <Link
                        to={`/details/${content.id}`}
                        className="bg-[#2d95a5] hover:bg-[#5097a2] text-white  py-2 px-4 text-sm rounded-lg"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination */}

            <div className="pagination">
              {contents.length > itemsPerPage && (
                <ul className="pagination-list flex items-center justify-center gap-2 mt-5 text-[#2d95a5]">
                  <li className="pagination-item">
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="pagination-link btn bg-[#2d95a5] rounded-full text-white px-4 py-2"
                    >
                      {"<"}
                    </button>
                  </li>
                  <li className="pagination-page-number">
                    Page {currentPage} of{" "}
                    {Math.ceil(contents.length / itemsPerPage)}
                  </li>
                  <li className="pagination-item">
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(contents.length / itemsPerPage)
                      }
                      className="pagination-link btn bg-[#2d95a5] rounded-full text-white px-4 py-2"
                    >
                      {">"}
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogAndArticle;
