import React from "react";

const Ads = () => {
  return (
    <>
      <section
        className='mt-10 font-ubuntu mb-10'
        data-aos='zoom-in'
        data-aos-easing='linear'
        data-aos-duration='300'
      >
        <div className='rounded-xl p-1 overflow-hidden  bg-[#76dbeb]'>
          <img src='../assets/add.png' alt='' className='w-full h-full' />
        </div>
      </section>
    </>
  );
};

export default Ads;
