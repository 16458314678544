import React from "react";

const Banner = ({ title, imageUrl }) => {
  return (
    <div className="relative ">
      <img
        src={imageUrl}
        alt="Banner Image"
        className="object-contain w-full h-full"
      />
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <h1 className="p-4 text-2xl sm:text-4xl md:text-5xl lg:text-5xl font-bold text-white text-center">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default Banner;
