import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ImageGallery = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const callPhotos = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/galleries?type=2",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);

        const photos = data.map((item) => ({
          src: item.file_path,
          width: index % 2 === 0 ? 1600 : 800,
          height: index % 2 === 0 ? 900 : 600,
        }));

        setPhotos(photos);

        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callPhotos();
  }, []);

  const [index, setIndex] = useState(-1);

  // const photos = [
  //   {
  //     src: "/assets/demo/image1.jpg",
  //     width: 800,
  //     height: 600,
  //   },
  //   { src: "/assets/demo/image2.jpg", width: 1600, height: 900 },
  //   { src: "/assets/demo/image1.jpg", width: 800, height: 600 },
  //   { src: "/assets/demo/image2.jpg", width: 1600, height: 900 },
  //   { src: "/assets/demo/image1.jpg", width: 800, height: 600 },
  //   { src: "/assets/demo/image2.jpg", width: 1600, height: 900 },
  // ];

  return (
    <>
      {/* <h2 className='text-3xl text-[#2d95a5] font-medium mb-4 mt-5 font-ubuntu'>
        Photo Gallery
      </h2> */}
      <PhotoAlbum
        componentsProps={() => ({
          imageProps: { crossOrigin: "anonymous" },
        })}
        layout="rows"
        photos={photos}
        onClick={({ index: current }) => setIndex(current)}
      />
      <Lightbox
        index={index}
        slides={photos}
        open={index >= 0}
        close={() => setIndex(-1)}
        components={{
          // Extend the default components to add the crossOrigin attribute
          image: ({ slide }) => (
            <img
              src={slide.src}
              alt={slide.alt}
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "0 auto" }}
              crossOrigin="anonymous" // Add the crossOrigin attribute here
            />
          ),
        }}
      />
    </>
  );
};

export default ImageGallery;
