import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import GoogleMapReact from "google-map-react";
import {
  FaEnvelope,
  FaFacebookSquare,
  FaInstagramSquare,
  FaMapMarkerAlt,
  FaPhone,
  FaTwitterSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  FaArrowRightFromBracket,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaLocationDot,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { IoMdMailOpen } from "react-icons/io";
import { MdAccessTimeFilled } from "react-icons/md";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ContactUs = () => {
  const [contacts, setContacts] = useState([]);
  const [saving, setSaving] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: 59.955413,
    lng: 30.337844,
  }); // default values

  useEffect(() => {
    const callContact = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/contacts",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);

        setContacts(data);

        console.log(contacts);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      } finally {
        setSaving(false);
      }
    };
    callContact();
  }, []);

  useEffect(() => {
    // ... [existing useEffect code]

    // After setting contacts, update map center if location data is available
    if (contacts.length > 0 && contacts[0].map_location) {
      const [lat, lng] = contacts[0].map_location.split(",").map(Number);
      setMapCenter({ lat, lng });
    }
  }, [contacts]); // Ensure useEffect depends on 'contacts'

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone no is required"),
    country: Yup.string().required("Country is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (saving) {
      return;
    }
    setSaving(true);

    try {
      const res = await fetch(
        process.env.REACT_APP_BASE_API_URL + "/enquiries",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      const data = await res.json();
      console.log(data);

      if (res.status !== 200) {
        const error = new Error(data.error || "Failed to submit enquiry");
        throw error;
      }

      resetForm();
      toast.success("Thank you for your enquiry!");
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false); // Always set saving state back to false
    }
  };

  return (
    <div className="max-w-full mx-auto mt-8">
      <ToastContainer />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <div
          className="grid grid-cols-1 gap-8 lg:grid-cols-2"
          data-aos="fade-left"
        >
          {/* Contact Information Section */}
          <div className="bg-[#4eb8c0] p-6 text-white font-ubuntu rounded-md shadow-lg">
            <h2 className="mb-4 text-3xl font-medium uppercase">
              Contact Information
            </h2>
            <p className="mb-4">
              Feel free to get in touch with us. We would love to hear from you!
            </p>

            <div>
              <p className="mb-2">
                You can also reach us at our office. Here are the details:
              </p>

              <div className="space-y-5 mt-10">
                <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                  <div>
                    <FaLocationDot size={20} className="text-white" />
                  </div>
                  <span className="text-center md:text-left">
                    Race Course Rd, Madhava Nagar, Gandhi Nagar, <br />{" "}
                    Bengaluru, Karnataka 560001
                  </span>
                </div>

                <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                  <div>
                    <IoMdMailOpen size={20} className="text-white" />
                  </div>
                  <span>info@missionorganprotection.com</span>
                </div>

                {/* <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                <div>
                  <FaPhoneFlip size={20} className="text-white" />
                </div>
                <span>+91-80-2237 0451-57, +91-80-2237 0463</span>
              </div> */}

                {/* <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                  <div>
                    <MdAccessTimeFilled size={20} className="text-white" />
                  </div>
                  <span>8 AM - 5 PM , Monday - Saturday</span>
                </div> */}
              </div>

              {/* {contacts.map((contact, index) => (
                <div className="p-6 space-y-4 text-md">
                  <div className="flex gap-2 font-ubuntu ">
                    <strong className="flex gap-3 justify-center  items-start">
                      <FaMapMarkerAlt className="mt-1" /> Address:
                    </strong>
                    <span>
                      {contact.address}, {contact.pin}
                    </span>
                  </div>
                  <div className="flex  gap-2 font-ubuntu">
                    <strong className="flex gap-3 justify-center">
                      <FaEnvelope className="mt-1" /> Email:
                    </strong>{" "}
                    <span>{contact.email}</span>
                  </div>
                  <div className="flex  gap-2 font-ubuntu">
                    <strong className="flex gap-3 justify-center">
                      <FaPhone className="mt-1" /> Phone:
                    </strong>{" "}
                    <span>{contact.phone}</span>
                  </div>
                </div>
              ))} */}
            </div>

           
            <div className="flex items-center gap-2 justify-center md:justify-start mt-5">
              <a
                href="https://www.instagram.com/mission_organ_protection/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram
                  size={50}
                  className="text-white cursor-pointer  bg-teal-800 p-2 rounded-2xl transition duration-300 ease-in-out transform hover:scale-110"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/mission-organ-protection-a63309314/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn
                  size={50}
                  className="text-white cursor-pointer  bg-teal-800 p-2 rounded-2xl transition duration-300 ease-in-out transform hover:scale-110"
                />
              </a>
              <a href="https://www.youtube.com/playlist?list=PLKb6HsmFgC8svn6ku5toXg70DmaQI_0KD" target="_blank" rel="noopener noreferrer">
                <FaYoutube
                  size={50}
                  className="text-white cursor-pointer  bg-teal-800 p-2 rounded-2xl transition duration-300 ease-in-out transform hover:scale-110"
                />
              </a>
              
              <a
                href="https://www.facebook.com/mission.organ.protection"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF
                  size={50}
                  className="text-white cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 bg-teal-800 p-2 rounded-2xl "
                />
              </a>
            </div>

          </div>

          {/* Form Section */}
          <div className="p-6 bg-white rounded-md shadow-md" data-aos="fade-left">
            <h2
              data-aos="fade-left"
              className="text-3xl font-medium mb-4 text-[#2d95a5] font-ubuntu"
            >
              Get in touch with us
            </h2>

            <Form>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:border-blue-300"
                  />
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="mt-1 text-xs text-red-500"
                  />
                </div>

                {/* Email Input */}
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:border-blue-300"
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="mt-1 text-xs text-red-500"
                  />
                </div>

                {/* Phone Number Input */}
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <Field
                    type="text"
                    id="phone"
                    name="phone"
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:border-blue-300"
                  />
                  <ErrorMessage
                    name="phone"
                    component="p"
                    className="mt-1 text-xs text-red-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country
                  </label>
                  <Field
                    type="text"
                    id="country"
                    name="country"
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:border-blue-300"
                  />
                  <ErrorMessage
                    name="country"
                    component="p"
                    className="mt-1 text-xs text-red-500"
                  />
                </div>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:border-blue-300"
                />
                <ErrorMessage
                  name="message"
                  component="p"
                  className="mt-1 text-xs text-red-500"
                />
              </div>

              {/* Submit Button */}

              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className="bg-[#2d95a5] hover:bg-[#2d95a5] text-white font-bold py-2 px-6 rounded-md focus:outline-none focus:ring-2 focus:border-blue-100 uppercase flex justify-center items-center gap-3"
                  disabled={saving} // Disable the button while saving
                >
                  {saving ? "Submitting..." : "Submit"}
                  <FaArrowRightFromBracket />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Formik>
      <div className="mt-10" style={{ width: "100%" }} data-aos="fade-left">
        <iframe
          className="w-full shadow-lg rounded-lg h-96"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.8087857196474!2d77.57798937472101!3d12.98407868733242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d1da15e473b%3A0x32d37e2b09a30ef2!2sMicro%20Labs%20Limited!5e0!3m2!1sen!2sin!4v1715754825686!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
