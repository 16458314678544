import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const NavNavbar = () => {
  const menuRef = useRef(null);
  const submenuRef = useRef(null);

  const menuItems = [
    { id: 1, menu: "HOME", url: "/home" },
    {
      id: 2,
      menu: "INTRODUCTION",
      items: [
        { id: 1, name: "About", url: "/introduction" },
        {
          id: 2,
          name: "Understanding Diabetes",
          url: "/understanding-diabetes",
        },
        {
          id: 3,
          name: "Understanding Hypertension & CVD",
          url: "/understanding-hypertension-and-cvd",
        },
      ],
    },
    
    { id: 4, menu: "DIET CHARTS", url: "/dietchart" },
    { id: 6, menu: "TESTIMONIALS", url: "/testimonials" },
    {
      id: 7,
      menu: "EDUCATION",
      items: [
        { id: 1, name: "Videos", url: "/op-videos" },
        { id: 2, name: "Yoga Videos", url: "/yoga-videos" },
        { id: 3, name: "Podcast", url: "/podcasts" },
        { id: 4, name: "Quiz", url: "/quiz" },
        { id: 5, name: "Latest Updates", url: "/latest-updates" },
        { id: 6, name: "Diabetes Risk Assessment", url: "https://www.health.gov.au/resources/apps-and-tools/the-australian-type-2-diabetes-risk-assessment-tool-ausdrisk/tool" },
        { id: 7, name: "Image Gallery", url: "/gallery" },
      ],
    },
    { id: 8, menu: "CONTACT US", url: "/contact-us" },
    {
      id: 9,
      menu: "FAQ's",
      items: [
        { id: 1, name: "Diabetes", url: "/faqs-diabetes" },
        { id: 2, name: "Hypertension & CVD", url: "/faqs-hypertension" },
      ],
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = (id) => {
    setOpenSubMenu(openSubMenu === id ? null : id);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setOpenSubMenu(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav
      ref={menuRef}
      className="relative z-50 flex items-center justify-between bg-navbar px-4 py-3"
    >
      <div className="lg:absolute xl:left-40 lg:left-16 xl:top-5 lg:top-9">
        <Link to="/">
          <img
            src="../assets/MOP.png"
            alt="Logo"
            className="w-16 md:w-20 lg:w-24 xl:w-36"
          />
        </Link>
      </div>

      {/* Hamburger Menu */}
      <div className="lg:hidden flex items-center ml-auto">
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          {isMenuOpen ? (
            <FaTimes className="w-6 h-6" />
          ) : (
            <FaBars className="w-6 h-6" />
          )}
        </button>
      </div>

      <div
        className={`fixed top-0 left-0 h-full w-full bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out ${
          isMenuOpen
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        } lg:hidden`}
        onClick={closeMenu}
      ></div>

      {/* small screen menu */}
      <ul
        className={`fixed top-0 right-0 h-full bg-white shadow-xl transition-transform transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } w-64 lg:hidden`}
      >
        <li className="flex justify-end p-4">
          <button
            onClick={closeMenu}
            className="text-gray-700 focus:outline-none"
          >
            <IoClose size={26} className="text-navbar" />
          </button>
        </li>
        {menuItems.map((item) => (
          <li key={item.id} className="relative">
            <div
              className="flex items-center justify-between px-4 py-2 space-y-5 text-lg font-normal text-navbar hover:text-white bg-white hover:bg-navbar"
              onClick={() =>
                item.items ? toggleSubMenu(item.id) : closeMenu()
              }
            >
              <Link to={item.url} className="block w-full">
                {item.menu}
              </Link>
              {item.items && <IoIosArrowDown className="cursor-pointer" />}
            </div>
            {item.items && (
              <ul
                className={`pl-4 transition-max-height duration-300 ease-in-out overflow-hidden ${
                  openSubMenu === item.id ? "max-h-screen" : "max-h-0"
                }`}
              >
                {item.items.map((subItem) => (
                  <li
                    key={subItem.id}
                    className="py-2 pl-4 text-gray-600 bg-gray-50 hover:bg-gray-200"
                  >
                    <Link to={subItem.url} onClick={closeMenu}>
                      {subItem.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      {/* large screen menu */}

      <div className="ml-auto hidden lg:flex items-center xl:mr-28">
        <ul className=" flex p-1 lg:text-sm space-x-7 lg:space-x-4 text-xl font-normal text-white font-sans">
          {menuItems.map((item, index) => (
            <li key={index} className="relative">
              <div className="flex group">
                <Link
                  to={item.url}
                  className="transition py-3 duration-300 ease-in-out transform hover:text-teal-300 hover:scale-110"
                >
                  <span className="relative inline-block" style={{ fontSize :17, fontWeight:600 }}>{item.menu}</span>
                </Link>

                {item.items?.length > 0 && (
                  <div className="relative group">
                    <IoIosArrowDown className="mt-4 transition-transform transform cursor-pointer group-hover:-rotate-180 ml-1" />
                    <ul
                      className="absolute z-10 hidden w-56 mt-4 transform -translate-x-1/2 bg-white rounded-md shadow-lg group-hover:block"
                      ref={submenuRef}
                    >
                      {item.items.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className="block px-4 py-3 text-gray-800 transition-all duration-300 ease-in-out hover:bg-teal-800 hover:text-white"
                        >
                          <Link to={subItem.url} className="block" style={{ fontSize :16, fontWeight:500 }}>
                            {subItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
        {/* <button className="hidden lg:block bg-white text-navbar text-base px-9 py-2 rounded-full font-sans font-medium ml-4">
          Action
        </button> */}
      </div>
    </nav>
  );
};

export default NavNavbar;
