import React from "react";

function YouTubeVideoPlayer({ videoLink }) {
  // Extract the video ID from the YouTube link
  const videoId = extractVideoId(videoLink);

  // Helper function to extract the video ID from a YouTube link
  function extractVideoId(url) {
    // Regular expression to match YouTube video URLs
    const regExp =
      /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/|)([a-zA-Z0-9_-]+)/;
    const match = url.match(regExp);

    if (match && match[1]) {
      return match[1];
    } else {
      // Handle invalid YouTube URLs here
      return null;
    }
  }

  if (!videoId) {
    // Handle invalid YouTube URLs or missing videoLink in the database
    return <div>Invalid YouTube video link</div>;
  }

  return (
    <div>
      <iframe
        title="YouTube Video"
        width="100%"
        height="250px"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default YouTubeVideoPlayer;
