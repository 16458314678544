import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Ads from "../Components/FirstComponents/Ads";
import Videos from "../Components/Common/Videos";
import { useEffect, useState } from "react";
import ListboxSelect from "../Utils/ListboxSelect";
import Banner from "../Utils/Banner";

function LatestUpdates() {
  const [contents, setContents] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: 0,
    name: "All",
  });
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    id: 0,
    name: "All",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    const callContents = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL +
            "/contents?content_category_id=4",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);
        setContents(data);
        console.log(contents);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callContents();
  }, []);

  useEffect(() => {
    const callLanguages = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/languages",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);
        const all = { id: 0, name: "All" };
        data.unshift(all);
        setLanguages(data);
        console.log(languages);
        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callLanguages();

    const callSubCategories = async () => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/content-subcategories",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // credentials: "include",
          }
        );

        const data = await res.json();
        console.log(data);
        const all = { id: 0, name: "All" };
        data.unshift(all);
        setSubCategories(data);

        if (!res.status === 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    callSubCategories();
  }, []);

  return (
    <>
      <div
        className='bg-gray-200 bg-no-repeat bg-contain '
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className='mx-auto '>
          {/* <Hero /> */}

          {/* <h1 className="text-[#2d95a5] flex flex-col lg:flex-row justify-between items-center bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10 uppercase">
            Latest Updates
          </h1> */}

          <Banner title='Latest Updates' imageUrl='/assets/Second_Banner.png' />

          <span className='flex justify-end gap-2 m-10'>
            <ListboxSelect
              label={"All Languages"}
              items={languages}
              onSelect={setSelectedLanguage}
            />
            <ListboxSelect
              label='All Sub Categories'
              items={subCategories}
              onSelect={setSelectedSubCategory}
            />
          </span>

          <div className='mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14 xl:ml-32 xl:mr-32'>
            {/* <MythsFacts /> */}
            {/* <p className='pt-10'>
              Language:{" "}
              {
                languages.find(
                  (language) => language.id === selectedLanguage.id
                )?.name
              }
            </p> */}

            <Videos
              contents={contents}
              subCategory={selectedSubCategory}
              language={selectedLanguage}
            />

            {/* <Ads /> */}
            {/* <Subscribe /> */}
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default LatestUpdates;
